<template>
  <span
    v-if="adressen?.length"
    :class="{
      'inline-flex': adressen.length === 1,
      'gap-2': !kissAndRide,
      'gap-3': kissAndRide,
    }"
  >
    <a
      :href="link"
      @click="link && link[0] == '#' ? handleCopy : undefined"
      :disabled="store.getters.isKantoorPermission"
      rel="noopener noreferrer nofollow"
      target="_blank"
      class="group"
    >
      <slot>
        <i v-if="icon" :class="icon" class="w-5" />
        <span
          v-if="adres && !hideAdres"
          class="group-hover:underline"
          :class="{
            'text-orange-500 font-bold bg-red-100 px-2 text-left': showGeocodeStatus && (!adres.lng || !adres.lat),
            'text-green-600 font-bold': showGeocodeStatus && (adres.lng && adres.lat),
          }"
        >
          <span v-if="useFindLuchthaven(adres)">Luchthaven {{ useFindLuchthaven(adres)?.name }}</span>
          <span v-else>{{ adres?.adres || adres }}</span>
        </span>
        <span class="tracking-tighter" v-if="showGeocodeStatus">
          <i v-if="adres.lng" class="fas fa-check text-xs text-green-600 rotate-2"></i>
          <i v-else class="fas fa-times text-xs text-red-600"></i>
          <i v-if="adres.lat" class="fas fa-check text-xs text-green-600 rotate-2"></i>
          <i v-else class="fas fa-times text-xs text-red-600"></i>
        </span>
      </slot>
    </a>

    <button @click="handleCopy" class="bg-dashboard-dark text-white rounded text-xs px-1 opacity-60 hover:opacity-100 w-6 text-center" v-if="adressen.length === 1" :disabled="isCopying">
      <i class="fas text-xs" :class="{ 'fa-copy': !isCopying, 'fa-spinner': isCopying }" />
    </button>

    <a
      v-if="kissAndRidelink"
      :href="kissAndRidelink"
      rel="noopener noreferrer nofollow"
      target="_blank"
      class="bg-dashboard-dark text-white rounded text-base px-1 opacity-60 hover:opacity-100 w-6 text-center"
    >
      <i class="fas fa-parking" />
    </a>
  </span>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'
import useFindLuchthaven from '@/hooks/useFindLuchthaven'
import useParsedAdressen from '@/hooks/useParsedAdressen'
import { useStore } from 'vuex'

const props = defineProps({
  labelIndex: Number,
  icon: String,
  showGeocodeStatus: Boolean,
  kissAndRide: Boolean,
  hideAdres: Boolean,
  adres: {
    type: [Array, String, Object],
    required: true,
  },
})

const store = useStore()
const adressen = useParsedAdressen(props)

const link = computed(() => {
  if (store.getters.isKantoorPermission) {
    return '#adres'
  }
  let result = 'https://www.google.com/maps/dir/'
  adressen.value.forEach((adres) => {
    result += `/${encodeURIComponent(adres.adres)}`
  })
  return result
})

const kissAndRidelink = computed(() => {
  if (!props.kissAndRide) return null
  let isLuchthavenFound = false

  let result = 'https://www.google.com/maps/dir/'
  adressen.value.forEach((adres) => {
    const luchthaven = useFindLuchthaven(adres)
    if (luchthaven?.name) {
      isLuchthavenFound = true
      result += `/${encodeURIComponent(luchthaven?.kissAndRide || `Kiss & Ride Luchthaven ${luchthaven?.name}`)}`
      return
    }
    result += `/${encodeURIComponent(adres.adres)}`
  })

  if (isLuchthavenFound) return result
  return null
})


const adresTest = computed(() => props.adres?.adres || props.adres)

const isCopying = ref(false)
const handleCopy = async (e) => {
  if (typeof e.preventDefault === 'function') {
    e.preventDefault()
  }

  const text = adresTest.value
  if (typeof text !== 'string') return

  isCopying.value = true

  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    try {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    } catch (error) {
      // Nothing
    }
  }

  setTimeout(() => {
    isCopying.value = false
  }, 500)
}
</script>
